.SmsMailingCardModalMainInfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 24px;

  & > .SmsMailingCardModalItem {
    justify-self: flex-start;
  }
}

.SmsMailingCardModalItem {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  gap: 6px;
}

.SmsMailingCardModalItemLabel {
  .TableHeader();
  color: @text-secondary;
}

.SmsMailingCardModalItemValue {
  .Text2();
}

.SmsMailingCardModalItemValue.SmsMailingCardModalItemValueSum {
  font-family: "Gilroy-700", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  color: @text-primary;
}

.SmsMailingCardModalDetailsInfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px 24px;
  margin-bottom: 24px;
}

.SmsMailingCardModalTextInfo {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  gap: 16px;
}

.SmsMailingCardModalErrorText {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 24px;
  .Text1();
  color: @error-500;
}