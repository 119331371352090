.CashboxTableContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .ant-table-wrapper {
    background-color: @background-tertiary;
    border-radius: 24px;
  }
}

.CashboxTableHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.CashboxTableHeader {
  display: flex;
  align-items: center;
  gap: 8px;

  .Headline2();

  .TooltipComponentTargetWrapper {
    margin: unset;
  }
}

.CashboxTableHeaderIcon {
  display: flex;
  align-items: center;
}

.CashboxTableDivider {
  width: 100%;
  height: 1px;
  background-color: @separator-tertiary;
  margin: 12px 0 24px 0;
}


.CashboxTableWrapper {
  .ant-table-tbody {
    .ant-table-row:has(.ant-table-row-expand-icon-cell):has(span):has(div):has(.CashboxTableIcon):has(.-rotate) > td {
      border-bottom: 0 !important;
    }

    .ant-table-cell.ant-table-row-expand-icon-cell {
      padding-right: 0 !important;
      padding-left: 32px !important;
    }

    .ant-table-cell {
      height: 51px;
      border-bottom: 1px solid @separator-tertiary;
    }

    .ant-table-cell-row-hover {
      background-color: @background-tertiary !important;
    }
  }

  .ant-table-thead {
    height: 64px;

    .ant-table-cell {
      background-color: @background-tertiary;
      border-bottom: transparent;
    }

    .ant-table-cell::before {
      background-color: transparent !important;
    }

    tr:first-child > *:first-child {
      border-start-start-radius: 24px !important;
    }

    tr:first-child > *:last-child {
      border-start-end-radius: 24px !important;
    }
  }

  .ant-pagination {
    padding: 12px 24px;
    margin: unset !important;

    .ant-pagination-item-active {
      background-color: transparent;

      a {
        color: @text-primary;
      }
    }

    .ant-pagination-options {
      margin-left: auto;
      margin-right: 24px;
    }

    .ant-select-selector {
      background-color: transparent;
      border-color: @separator-primary;
      border-radius: 8px;
    }

    .ant-select-arrow {
      color: @text-primary;
    }

    .ant-pagination-disabled .ant-pagination-item-link {
      color: @grey-420;
    }
  }

  .ant-pagination-options {
    margin-left: auto;

    svg path {
      fill: @text-primary;
    }

    @media @mobile {
      display: inline-block;
    }
  }

  .ant-table-container {
    // TODO: ЗАМЕНИТЬ на @background-secondary после обновления компонентов!!!!
    background-color: @primary-700;
    border-radius: 24px 24px 0 0;
  }

  .ant-table-cell {
    .TextBold();
  }

  .ant-table-cell {
    .-minus {
      color: @red-420;
    }
  }

  .ant-table-wrapper {
    background-color: @background-secondary;
  }

  .ant-table-content {
    .TableHorizontalScroll();
  }

  .ant-table-expanded-row .ant-table-cell {
    padding: 0 !important;
  }
}

.CashboxTableIcon {
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &.-rotate {
    transform: rotate(180deg);
  }
}

.CashboxTableInside {
  display: flex;
  flex-direction: column;

  .CashboxTableInsideRow {
    display: inline-grid;
    padding: 16px 16px 16px 32px;
    width: 100%;
    .Text1();

    &:hover {
      background-color: @background-tertiary;
    }
  }

  .CashboxTableInsideHallRow {
    background-color: @background-tertiary;
    border-radius: 4px;
  }

  .CashboxTableInsideItem {
    display: flex;
    align-items: center;
  }
}

.CashboxTableInside > div {
  display: flex;
  flex-direction: column;
}

.NewCashboxTableInside {
  .CashboxTableInsideItem:first-child {
    padding-left: 0;
  }
  .CashboxTableInsideItem:nth-child(2) {
    padding-left: 36px;
  }
  .CashboxTableInsideItem:nth-child(3) {
    padding-left: 38px;
  }
  .CashboxTableInsideItem:nth-child(4) {
    padding-left: 40px;
  }
}

.NewCashboxTableInside .CashboxTableInsideRow {
  grid-template-columns: 20% 20% 20% 40%;
}

.SZHCashboxTableInside {
  .CashboxTableInsideItem:first-child {
    padding-left: 0;
  }
  .CashboxTableInsideItem:nth-child(2) {
    padding-left: 36px;
  }
  .CashboxTableInsideItem:nth-child(3) {
    padding-left: 38px;
  }
  .CashboxTableInsideItem:nth-child(4) {
    padding-left: 40px;
  }
}

.SZHCashboxTableInside .CashboxTableInsideRow {
  grid-template-columns: 20% 20% 20% 40%;
}

.UKMCashboxTableInside {
  .CashboxTableInsideItem:first-child {
    padding-left: 0;
  }
  .CashboxTableInsideItem:nth-child(2) {
    padding-left: 22%;
  }
  .CashboxTableInsideItem:nth-child(3) {
    padding-left: 22%;
  }
  .CashboxTableInsideItem:nth-child(4) {
    padding-left: 22%;
  }
  .CashboxTableInsideItem:nth-child(5) {
    padding-left: 22%;
  }
  .CashboxTableInsideItem:nth-child(6) {
    padding-left: 22%;
  }
  .CashboxTableInsideItem:nth-child(7) {
    padding-left: 22%;
  }
}

.UKMCashboxTableInside .CashboxTableInsideRow {
  grid-template-columns: 16% 14% 14% 14% 14% 14% 14%;
}

.OldCashboxTableInside {
  .CashboxTableInsideItem:first-child {
    padding-left: 0;
  }
  .CashboxTableInsideItem:nth-child(2) {
    padding-left: 32px;
  }
  .CashboxTableInsideItem:nth-child(3) {
    padding-left: 30px;
  }
  .CashboxTableInsideItem:nth-child(4) {
    padding-left: 30px;
  }
  .CashboxTableInsideItem:nth-child(5) {
    padding-left: 30px;
  }
  .CashboxTableInsideItem:nth-child(6) {
    padding-left: 30px;
  }
}

.OldCashboxTableInside .CashboxTableInsideRow {
  grid-template-columns: 15% 17% 17% 17% 17% 17%;
}

.CashboxTableInsideItem:first-child {
  gap: 12px;
}

.CashboxTableColumnHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 6px;

  svg path {
    fill: @icon-tertiary;
  }
}
