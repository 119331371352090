.OrderDetailsModalHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.OrderDetailsModalHeaderContainer .OrderCardInfoStatus {
  margin-left: 12px;
}

.OrderDetailsModalContent {
  display: flex;
  justify-content: space-between;
  gap: 64px;
}

.OrderDetailsModalForm {
  display: flex;
  flex-direction: row;
  height: 824px;
  gap: 18px;

  @media @mobile {
    flex-direction: column;
    gap: 32px;
  }
}

.OrderDetailsModalFormBlock {
  display: flex;
  gap: 18px;
  flex-direction: column;
  width: 295px;
}

.OrderDetailsModalSubtitle {
  .Subtitle1();
}

.OrderDetailsModalText {
  .Subtitle1();
  color: @neutral-600;
}

.OrderCheckoutModalFooterButtonDelete.ant-btn-default {
  border-color: @error-500 !important;
}