.NewChangePhoneStatusLabel.StatusLabel {
  background-color: @primary-500;
}

.ChangePhoneTable {
  .ant-pagination-options {
    margin-left: auto;

    svg path {
      fill: @neutral-500;
    }

    @media @mobile {
      display: inline-block;
    }
  }

  .ant-select-selector {
    background-color: transparent !important;
    border: 1px solid @neutral-600 !important;
  }

  .ant-table-content tr > th:first-child,
  .ant-table-content tr > td:first-child {
    padding-left: 32px;
  }

  .ant-table-content tr > th:last-child,
  .ant-table-content tr > td:last-child {
    padding-right: 32px;
  }

  .ant-table-tbody {
    background-color: @primary-800;

    .ant-table-cell {
      .Text1();

      @media @mobile {
        border-bottom: none;
      }
    }
  }

  .ant-table-thead {
    .ant-table-cell {
      background-color: @primary-700;
      font-weight: 500;
      font-size: 14px;

      @media @mobile {
        border-bottom: none;
      }

      &:before {
        display: none;
      }
    }
  }

  .ant-table-content .ant-table-thead .ant-table-cell,
  .ant-table-content .ant-table-tbody .ant-table-cell {
    border-bottom: 1px solid @primary-700;
  }

  .ant-table-row:hover > td {
    background-color: @primary-700 !important;
  }

  .ant-pagination {
    background-color: @primary-700;
    padding: 12px 12px 12px 16px;
    margin: 0 !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: 14px;
    font-weight: 500;

    .ant-pagination-item-active {
      background-color: transparent;

      a {
        color: white;
      }
    }
  }

  .ant-table-content {
    .TableHorizontalScroll();
  }
}

.ChangePhoneDate {
  .Text1();
}

.ChangePhoneTime {
  .Text1();
  color: @neutral-600;
}
