.RadioWrapper {
  display: flex;
  flex-direction: row;
  align-items: baseline;

  .ant-radio-inner {
    border: 2px solid @neutral-600;
    background: transparent;
  }

  .ant-radio-checked .ant-radio-inner {
    background-color: @secondary-500;
  }

  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #F8E800;
  }

  .ant-radio-checked .ant-radio-inner::after {
    top: 7px;
    left: 7px;
    background-color: @primary-800;
    width: 24px;
    height: 24px;
  }

}

.LargeRadioWrapper {
  height: 24px;
  gap: 12px;

  label {
    .Text2();
  }

  .ant-radio-inner {
    width: 24px;
    height: 24px;
  }

  .ant-radio-checked .ant-radio-inner::after {
    width: 24px;
    height: 24px;
  }
}

.MediumRadioWrapper {
  height: 20px;
  gap: 8px;

  label {
    .Text1();
  }

  .ant-radio-inner {
    width: 20px;
    height: 20px;
  }

  .ant-radio-checked .ant-radio-inner::after {
    width: 20px;
    height: 20px;
  }
}


.RadioGroupOption {
  padding-bottom: 8px;
  height: 34px;
}

.RadioGroupInputWrapper {
  margin-bottom: -8px;
}
