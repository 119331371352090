.LoginWrapper {
  width: 100vw;
  height: 100vh;
  background-color: @primary-900;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LoginFormWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin: auto 16px;
  padding: 32px 128px 48px 128px;
  border-radius: 16px;
  background-color: @primary-800;
  width: 584px;
  max-width: 584px;
  z-index: 2;

  @media @mobile {
    width: unset;
    gap: 24px;
    padding: 24px 16px 32px 16px;
  }
}

.LoginFormLogo {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: calc(50vh - 340px);
  z-index: 1;

  @media @mobile {
    margin-top: calc(50vh - 295px);
  }
}

.LoginFormIcon {
  width: 64px;
  height: 64px;

  @media @mobile {
    width: 48px;
    height: 48px;
  }
}

.LoginFormHeader {
  .Headline3();

  @media @mobile {
    .Subtitle1();
  }
}

.LoginFormLabel {
  .Headline2();
}

.LoginFormFieldWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.LoginFormField,
.LoginFormEnter {
  width: calc(100vw - 64px) !important;
  max-width: 328px;
}

.ErrorMessage {
  padding-top: 6px;
  .Text1();
  text-align: center;
  color: @error-500;


  & > svg {
    margin-right: 5px;
  }
}
