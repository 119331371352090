// todo: сделать отдельный инпут для фильтров и применить его везде
.ChangePhoneFilterContentContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .VerticalScrollInvisible();
  max-height: calc(100vh - 175px);
}

.ChangePhoneFilterSearch {
  @media @mobile {
    align-self: center;
  }
}

.ChangePhoneFilterSearch .ant-input-affix-wrapper {
  background-color: @primary-700;
  border-radius: 8px;
  border: none;
  height: 40px;
}

.ChangePhoneFilterSearch input {
  background-color: @primary-700;

  &::placeholder {
    color: @primary-200;
  }
}

.ChangePhoneFilterSearch > .ant-input-affix-wrapper-focused,
.ChangePhoneFilterSearch > .ant-input-affix-wrapper:hover {
  border: none;
  box-shadow: none;
}