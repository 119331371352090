.ModalWrapper {
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ModalCloseIcon {
  position: absolute;
  top: 24px;
  right: 24px;

  &:hover {
    cursor: pointer;
  }
}

.ModalOverlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: @modal-background;
  z-index: 9998;
  top: 0;
  left: 0;
}


.SideModalWrapper {
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
}

.SideModalContainer {
  width: 1172px;
  height: 100vh;
  border-radius: 16px 0 0 16px;
  background: @primary-800;
  z-index: 9999;
  animation: slideRight 500ms ease;
  padding: 30px 56px 0 56px;
  .VerticalScroll();

  @media @mobile {
    width: 100vw;
    animation: unset;
    border-radius: unset;
    padding: 12px 12px 0 12px;
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(1172px);
  }
  100% {
    transform: translateX(0px);
  }
}

// new modal

.ModalComponentWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ModalComponentWrapper .ant-modal-content {
  background-color: @background-secondary;
  border-radius: 16px;

  & .ant-modal-header,
  & .ant-modal-content,
  & .ant-modal-footer {
    background: transparent;
  }
}

.ModalComponentWrapper.ModalComponentPrimaryWrapper {
  & .ant-modal-content {
    gap: 40px;
    padding: 40px;

    @media @mobile {
      gap: 24px;
      padding: 24px 16px;
    }
  }

  & .ant-modal-content .ant-modal-header {
    margin-bottom: 24px;

    @media @mobile {
      margin-bottom: 16px;
    }
  }

  & .ant-modal-content .ant-modal-body {
    margin-bottom: 40px;

    @media @mobile {
      margin-bottom: 24px;
    }
  }

}

.ModalComponentWrapper.ModalComponentSecondaryWrapper {
  & .ant-modal-content {
    gap: 32px;
    padding: 32px;

    @media @mobile {
      gap: 24px;
      padding: 24px 16px;
    }
  }

  & .ant-modal-content .ant-modal-header {
    margin-bottom: 12px;

    @media @mobile {
      margin-bottom: 8px;
    }
  }

  & .ant-modal-content .ant-modal-body {
    margin-bottom: 32px;

    @media @mobile {
      margin-bottom: 24px;
    }
  }

}

.ModalComponentWrapper .ant-modal-close {
  top: 24px;
  right: 24px;
}

.ModalComponentHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.ModalComponentHeader {
  .Headline2();

  @media @mobile {
    .Headline3();
  }
}

.ModalComponentFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ModalComponentFooter.ModalComponentPrimaryFooter {
  justify-content: flex-start;
  gap: 24px;

  @media @mobile {
    gap: 16px;
  }
}

.ModalComponentFooter.ModalComponentSecondaryFooter {
  justify-content: flex-end;
  gap: 16px;
}