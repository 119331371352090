.DashboardInfoTabsContainer {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  background-color: @primary-900;
  max-width: 344px;
  height: 40px;
  border-radius: 12px;
  padding: 2px;
  .Button1();
  color: @neutral-500;

  @media @mobile {
    gap: 2px;
    background-color: @primary-800;
    max-width: 74px;
    padding: 4px;
    border-radius: 12px;
  }
}

.DashboardInfoTab {
  width: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;

  svg path {
    fill: @neutral-600;
  }
}

.DashboardInfoTabActive {
  background-color: @primary-700;

  svg path {
    fill: @neutral-500;
  }
}