body {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.PageLayout {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 32px;
  width: 100vw;
  height: 100vh;
  background-color: @primary-900;
}

.PageContainer {
  container-name: page;
  container-type: inline-size;
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  background-color: @primary-900;
  flex: 1 1 auto;
  transition: width 0.5s ease-in-out;

  @media @mobile {
    width: 100vw;
    height: 100vh;
    padding: 56px 0 30px;
    overflow-x: hidden;
  }
}

.PageContainerWrapper {
  display: flex;
  flex-direction: column;
  gap: 22px;
  flex-grow: 1;

  @media @mobile {
    gap: 16px;
  }
}

@container page (width > 1399px) {
  .PageContainerWrapper {
    width: 1400px;
    max-width: 1400px;
    margin: 0 auto;
  }
}

.PageContent {
  display: flex;
  max-width: 1400px;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 24px;
  max-height: calc(100vh - 126px);
  .VerticalScrollInvisible();

  @media @mobile {
    gap: 16px;
  }
}

.ToggleFiltersButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: @primary-800;
  border: none;
  height: 40px;
  width: 128px;
  color: @neutral-500;
  cursor: pointer;
  font-size: 14px;
  gap: 8px;
  font-weight: 600;

  & > span {
    color: @neutral-500;
  }
}

.MobilePageFixedNavigation {
  position: fixed;
  top: 0;
  display: flex;
  width: 100vw;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: @primary-800;
  border-bottom: 1px solid @primary-600;
  height: 56px;
  padding: 0 10px;
  z-index: 10;
}

.MenuMobileButton {
  padding: 8px;
  border-bottom-right-radius: 12px;
  z-index: 2147483647;
}

.MenuMobileLogoWrapper {
  display: flex;
  max-height: 56px;
  align-items: center;
  gap: 8px;

  .MenuMobileLogoTitle {
    max-width: 50px;
    font-family: "Gilroy-500", sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }
}

.FilterMobileButton {
  padding: 8px;
  border-bottom-left-radius: 12px;
  z-index: 2147483647;
}

//filter
.FilterWrapper {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  flex-shrink: 0;
  gap: 24px;
  min-height: 100vh;
  max-height: 100vh;
  transition: width 0.5s ease-in-out;
  width: 320px;
  background: @primary-800;
  z-index: 4;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  padding: 32px 24px;
  overflow-x: hidden;
  overflow-y: hidden;
  .Shadow();

  @media @mobile {
    min-width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    border-radius: 0;
    overflow-y: auto;
    gap: 20px;
    padding: 24px 16px;
  }
}

.FilterWrapper.FilterWrapperInactive {
  box-sizing: border-box;
  width: 72px;
  padding: 32px 12px;

  @media @mobile {
    display: none;
  }
}

.FilterWrapper.FilterWrapperActive {
  right: 0;
}

.FilterVisibilityButton.ant-btn.ant-btn-default {
  border: 1px solid @primary-600;

  &:not([disabled]) {
    &:hover,
    &:focus,
    &:active {
      border: 1px solid @primary-600;
    }
  }
}

.FilterClose {
  position: absolute;
  top: 32px;
  right: 24px;
  cursor: pointer;
}

.FilterHeaderText {
  .Headline2();
}

.FilterApplyButtonWrapper button {
  position: absolute;
  bottom: 24px;
  width: 272px;

  @media @mobile {
    align-self: center;
    width: calc(100% - 32px);
  }
}

// todo: перенести сюда остатки общих фильтровых стилей. Заменить компоненты.

.FilterContentContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: calc(100vh - 175px);
  .VerticalScrollInvisible();
}

.FilterSearch {
  @media @mobile {
    align-self: center;
  }
}
