.ComponentDashboardItemSkeleton {
  background-color: @primary-700;

  .ant-skeleton-header {
    margin-bottom: 24px;
  }

  .ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
    width: 100% !important;
  }
}

.ComponentDashboardItem {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 180px;
  padding: 20px;
  border-radius: 20px;
  position: relative;

  @media @mobile {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px 8px;
    min-width: 180px;
    height: 110px;
    padding: 12px;
    border-radius: 12px;
  }
}

.DashboardItemInfoWrapper {
  position: absolute;
  top: 20px;
  right: 20px;

  @media @mobile {
    top: 12px;
    right: 12px;
  }
}

.DashboardItemLabel {
  max-width: 110px;
  font-family: "Gilroy-700", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  padding-top: 20px;
  padding-bottom: 6px;

  @media @mobile {
    max-width: 90px;
    font-family: "Lato-700", sans-serif;
    font-weight: 700;
    font-size: 14px;
    font-style: normal;
    line-height: 16.5px;
    padding: unset;
  }
}

.DashboardItemLabelValue {
  display: flex;
  align-items: baseline;
  .Headline3();
}

.DashboardItemIntegerValue {
  white-space: nowrap;
}

.DashboardItemFractionValue {
  font-size: 14px;
}

.DashboardItemIconWrapper {
  width: 50px;
  min-width: 50px;

  @media @mobile {
    width: 40px;
    min-width: 40px;
  }
}

.DashboardItemIcon {
  width: 100%;
}
