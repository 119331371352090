.NotFound {
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 514px;

  .NotFoundImage {
    max-width: 320px;
    max-height: 282px;
    margin: 0 auto 32px;
  }

  .NotFoundTitle {
    .Headline2();
    margin-bottom: 32px;
    max-width: 378px;
    text-align: center;

    @media @mobile {
      .Headline3();
      margin-bottom: 24px;
      max-width: 320px;
    }
  }

  .NotFoundSubtitle {
    .Subtitle2();
    line-height: 24px;
  }
}

.NotFoundFullScreen {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: @background-tertiary;
  border-radius: 24px;
}
