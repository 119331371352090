.workersStatus {
  padding: 4px 12px 4px 8px;
  background-color: @primary-700;
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-transform: capitalize;

  &::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 4px;
  }

  &.working {
    &::before {
      background-color: @green-500;
    }
  }

  &.fired {
    &::before {
      background-color: @error-500;
    }
  }

  &.blocked {
    &::before {
      background-color: @neutral-600;
    }
  }
}

.workerCreateModal {
  .ant-radio {
    .ant-radio-inner {
      background-color: transparent;
      border-color: @neutral-600;
    }
  }

  .ant-radio-checked {
    .ant-radio-inner {
      background-color: @secondary-500;
      border-color: @secondary-500;

      &::after {
        background-color: @neutral-900;
      }
    }
  }

  .-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-top: 20px;

    .ant-form-item-label > label {
      .Subtitle1()

    }

    @media @mobile {
      gap: 0;
      padding-top: 6px;

      .ant-radio-wrapper {
        margin-bottom: 12px;
        margin-right: 24px;
      }
    }
  }

  .-wrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;

  }

  .-divider {
    border-block-start: 1px solid @primary-600;
    margin: 0 0 12px 0;
  }

  .-input {
    height: 48px !important;

    &::placeholder {
      color: @primary-500 !important;
    }
  }

  .-subtitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }

  .-maskedInput {
    height: 56px;
    background-color: @primary-700;
    border-color: transparent;
    color: @neutral-500;
    border-radius: 8px;
    padding: 15px 16px 17px 16px;

    &::placeholder {
      color: @primary-500;
    }

    &:focus {
      border-color: @primary-600;
    }
  }

  // NEW MODAL
  .-errorMessage {
    .Text1();
    font-size: 16px;
    line-height: 1.5;
    color: @error-500;
  }

}

.WorkersModal {

  .WorkersModalHeaderText {
    .Headline1();

    @media @mobile {
      .Headline3();
    }
  }

  .SubmitButton {
    width: 122px;
  }

  .ant-modal-content {
    background-color: @primary-800;
    border-radius: 16px;

    .anticon-close {
      svg path {
        fill: @neutral-600;
      }
    }

    .ant-modal-footer {
      text-align: start;

      @media @mobile {
        margin-top: 0;
      }

    }

    .ant-modal-header {
      background-color: @primary-800;

      .ant-modal-title {
        font-size: 32px;
        font-weight: 700;
        line-height: 39px;
      }

    }
  }

  &.-successModal {
    .ant-modal-header {
      text-align: center;
    }

    .ant-modal-close {
      display: none;
    }

    .ant-modal-footer {
      text-align: center;

      .ant-btn-primary {
        width: auto;
      }
    }

    .-image {
      display: flex;
      margin: 32px auto 32px;
    }
  }

  &.-confirmModal {
    .ant-modal-footer {
      text-align: end;
    }

    .ant-modal-close {
      display: none;
    }

    .ant-modal-body {
      margin-bottom: 24px;
      margin-top: 16px;
    }

    .cancelButton {
      height: 48px;
      background-color: transparent;
      border-color: @neutral-600;
      padding: 3px 24px;

      @media @mobile {
        height: 40px;
      }

      span {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      }
    }

    .submitButton {
      height: 48px;
      box-shadow: none;
      padding: 3px 24px;

      @media @mobile {
        height: 40px;
      }

      span {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }
}
