.TooltipComponentTargetWrapper {
  display: flex;
  margin-top: 4px;
  align-self: center;

  @media @mobile {
    margin-top: 2px;
    width: 20px;
  }
}

.TooltipComponentTargetWrapper:hover {
  cursor: pointer;
}

.TooltipComponentWrapper .ant-popover-content .ant-popover-inner {
  border-radius: 12px;
  background-color: @background-tertiary;
  .Shadow1();

  .ant-popover-inner-content {
    padding: 12px 16px !important;
    max-width: 320px;
    .Overline();
  }
}

.TooltipComponentWrapper {
  .ant-popover-arrow-content::before {
    background-color: @background-tertiary;
  }
}

.TooltipComponentModal {
  padding-top: 0 !important;

  .ant-popover-content {
    .ant-popover-arrow {
      display: none !important;
    }

    .ant-popover-inner {
      &:extend(.White100Background);

      .ant-popover-inner-content {
        border-radius: 16px !important;
        max-width: 100% !important;
      }
    }
  }
}