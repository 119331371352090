@import "containers/MainMenu/LeftMenu/LeftMenuOption/styles.less";

.LeftMenuContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  transition: width 0.5s ease-in-out;
  overflow-x: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;

  @media @mobile {
    width: auto;
  }
}

.LeftMenuContainerMinified {
  max-height: calc(100vh - 250px);
  .VerticalScroll();
}

.LeftMenuHeader {
  padding: 0 24px;
  .Overline();
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: @neutral-600;
}

.LeftMenuContentContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: calc(100vh - 370px);
  .VerticalScroll();

  @media @desktopMedium {
    max-height: calc(100vh - 340px);
  }

  @media @mobile {
    max-height: calc(100vh - 300px);
  }
}

.LeftMenuItem > .ant-collapse-header {
  align-items: center !important;
  padding: 0 24px !important;
  border-radius: 0 !important;
  height: 48px;

  &:hover {
    background-color: @primary-800;
  }
}

.LeftMenuItem > .ant-collapse-header > .ant-collapse-header-text {
  display: flex;
  .Subtitle2();
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
}

.LeftMenuOptionLink {
  display: flex;
  align-items: center;
  padding-left: 60px;
  height: 48px;
  .Subtitle2();

  &:hover {
    background-color: @primary-800;
    color: @neutral-500;
  }
}

.LeftMenuPanelWrapper {
  display: flex;
  gap: 12px;
}

.ChevronUp {
  rotate: (-180deg);
  transition: rotate 0.3s;
}

.ChevronDown {
  rotate: (-0);
  transition: rotate 0.3s;
}