// filters
.FilterContentContainer {
  .-CheckboxWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .-TitleWrapper {
    display: flex;
    justify-content: space-between;
  }

  .-TimeWrapper {
    display: flex;
    margin-top: 8px;
    color: @neutral-500;
    .Caption();


    .-additionalTime {
      color: @neutral-600;
    }
  }
}

// dashboards
.CashboxDashboards {
  display: flex;
  gap: 14px;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 24px 16px;
  min-height: 156px;
  max-height: 156px;
  background-color: @background-tertiary;
  border-radius: 24px;
  .HorizontalScroll();
}

.CashboxDashboard {
  max-width: 180px;
  min-width: 165px;
  width: 100%;
  height: 108px;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .-titleWrapper {
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }

  .-title {
    .Text1();
    font-weight: 600;
    margin: 0;
    max-width: 90px;
    min-width: 75px;
  }

  .-tooltipWrapper {
  }

  .-valueWrapper {
    padding-left: 4px;
    display: flex;
    align-items: end;
  }

  .-integer {
    font-family: "Gilroy-700", sans-serif;
    color: @neutral-500;
    font-size: 18px;
    margin: 0;
    line-height: 18px;
  }

  .-decimal {
    font-family: "Lato-700", sans-serif;
    color: @neutral-500;
    font-size: 14px;
    margin: 0;
    line-height: 16.5px;
  }
}
