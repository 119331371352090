.SmsMailingCreatePreviewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  width: 470px;
  min-width: 470px;
  max-width: 470px;
  padding: 60px 24px;
  background-color: @background-primary;
  border: 1px solid @separator-tertiary;
  border-radius: 24px;

  position: sticky;
  right: 0;
  top: 0;
  height: fit-content;

  @media @mobile {
    display: none;
  }
}

.SmsMailingCreatePreviewPhoneImage {
  position: relative;
}

.SmsMailingCreatePreviewPhoneTime {
  position: absolute;
  top: 28px;
  left: 42px;
}

.SmsMailingCreatePreviewPhoneTimeText {
  .Text1();
}

.SmsMailingCreatePreviewPhoneMessageDateTime {
  position: absolute;
  top: 125px;
  left: 115px;
}

.SmsMailingCreatePreviewPhoneMessageDateTimeText {
  .Caption();
  color: @label-color-dark-secondary;
}

.SmsMailingCreatePreviewPhoneMessageWrapper {
  position: absolute;
  top: 155px;
  left: 34px;
  width: 278px;
  max-height: 170px;
  background-color: @primary-800;
  padding: 5px 10px;
  border-radius: 14px;
}

.SmsMailingCreatePreviewPhoneMessageText {
  display: block;
  max-height: 160px;
  background-color: @primary-800;
  .Text1();
  .VerticalScroll();
}

.SmsMailingCreatePreviewMailingInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  width: 342px;
}

.SmsMailingCreatePreviewMailingInfoItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & span {
    .Body2();
  }
}

.SmsMailingCreatePreviewMailingInfoSeparator {
  width: 100%;
  border-color: @separator-tertiary;
}

.SmsMailingCreatePreviewMailingInfoItem.SmsMailingCreatePreviewMailingInfoItemTotal span {
  .Headline3();
}

.SmsMailingCreatePreviewInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 16px;
  width: 342px;
  border-radius: 12px;
  background-color: @background-secondary;
}

.SmsMailingCreatePreviewInfoText {
  .Caption();
  font-style: italic;

  &.SmsMailingCreatePreviewInfoLink {
    color: @text-complementary;
    text-decoration: none;
  }
}
