.HallPickerModalContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 812px;
  background-color: @primary-800;
  border-radius: 24px;
  margin-top: 192px;
  // margin-top - 192, margin-bottom - 152. 192+152=344
  max-height: calc(100vh - 344px);
  padding-top: 32px;
  padding-bottom: 48px;
  z-index: 9999;

  @media @mobile {
    max-width: calc(100vw - 32px);
    margin: auto 16px;
    padding: 32px 0;
    max-height: calc(100vh - 135px);
  }
}