.AddToCartCounterGroupedButtonText {
  .Headline3();
}

.AddToCartQuantity {
  width: 43px;
  background-color: @primary-600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AddToCartQuantityText {
  .Subtitle2();
}

.ant-btn-primary[disabled] .AddToCartCounterGroupedButtonText {
  color: @neutral-600;
}