// auth
.AuthInputWrapper {
  & > .ant-input,
  & > .ant-input-affix-wrapper,
  & > .ant-input-password {
    height: 56px;
    padding: 12px 16px 17px 16px;
    .Subtitle2();
    box-shadow: none;

    &::placeholder {
      color: @primary-100;
    }
  }
}

.AuthInputWrapper > .ant-input-affix-wrapper > .ant-input {
  background-color: @primary-700;
  border-color: @primary-700;
  .Subtitle2();

  &::placeholder {
    color: @primary-100;
  }
}

.AuthInputWrapper:not(.ErrorInputWrapper) {
  & > .ant-input:hover,
  & > .ant-input-affix-wrapper:hover,
  & > .ant-input:focus,
  & > .ant-input-affix-wrapper:focus,
  & > .ant-input:active,
  & > .ant-input-affix-wrapper:active {
    box-shadow: none;
    border: 1px solid @primary-600;
  }
}

.AuthInputWrapper.ErrorInputWrapper {
  & > .ant-input,
  & > .ant-input-number,
  & > .ant-input-affix-wrapper,
  & > .ant-input-affix-wrapper > .ant-input {
    background-color: @primary-900 !important;
  }
}

.AuthInputWrapper.ErrorInputWrapper {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px @primary-900 inset !important;
  }
}

.AuthInputWrapper.ErrorInputWrapper > .ant-input-affix-wrapper > .ant-input-suffix > span {
  color: @primary-600;
}

// general
.InputWrapper > .ant-input,
.InputWrapper > .ant-input-number,
.InputWrapper > .ant-input-affix-wrapper,
.InputWrapper > .ant-input-password {
  background-color: @primary-700;
  border-color: @primary-600;

  &::placeholder {
    color: @primary-500;
  }
}

.InputWrapper > .ant-input-affix-wrapper > .ant-input {
  background-color: @primary-700;
  border-color: @primary-700;
  .Subtitle2();

  &::placeholder {
    color: @primary-100;
  }
}

.ant-input:-webkit-autofill:hover,
.ant-input:-webkit-autofill:focus,
.ant-input:-webkit-autofill:active,
.ant-input:-webkit-autofill {
  -webkit-text-fill-color: @neutral-500;
  caret-color: @neutral-500;
  // autofill background color workaround
  transition: background-color 600000s ease-in-out 0s;
}

.InputWrapper:not(.ErrorInputWrapper) > .ant-input-affix-wrapper > .ant-input-suffix > span {
  color: @primary-500;
}

// borders
.ErrorInputWrapper.InputWrapper > .ant-input,
.ErrorInputWrapper.InputWrapper > .ant-input-number,
.ErrorInputWrapper.InputWrapper > .ant-input-affix-wrapper {
  border: 1px solid @error-500 !important;
  box-shadow: none !important;
}

.InputWrapper > .ant-input-status-error {
  border: 1px solid @error-500 !important;
  box-shadow: none !important;
}

.TextAreaComponentWrapper.InputWrapper:has(.ant-input-status-error) {
  border: 1px solid @error-500 !important;
  box-shadow: none !important;

  & > .ant-input-status-error {
    border: unset !important;
    box-shadow: none !important;
  }
}

.TextAreaComponentWrapper.InputWrapper.ErrorInputWrapper:not(:has(.ant-input-status-error)) {
  border: 1px solid @error-500 !important;
  box-shadow: none !important;

  & > .ant-input, .ant-input-number, .ant-input-affix-wrapper {
    border: unset !important;
    box-shadow: none !important;
  }
}

.InputWrapper:not(.ErrorInputWrapper) {
  & > .ant-input:hover,
  & > .ant-input-number:hover,
  & > .ant-input-affix-wrapper:hover,
  & > .ant-input:active,
  & > .ant-input-number:active,
  & > .ant-input-affix-wrapper:active,
  & > .ant-input:focus,
  & > .ant-input-number:focus,
  & > .ant-input-affix-wrapper:focus {
    border: 1px solid @primary-500;
  }
}

.InputWrapper.InputWrapperBorderless:not(.ErrorInputWrapper) {
  & > .ant-input,
  & > .ant-input-number,
  & > .ant-input-affix-wrapper,
  & > .ant-input-affix-wrapper-focused,
  & > .ant-input:hover,
  & > .ant-input-number:hover,
  & > .ant-input-affix-wrapper:hover,
  & > .ant-input:active,
  & > .ant-input-number:active,
  & > .ant-input-affix-wrapper:active,
  & > .ant-input:focus,
  & > .ant-input-number:focus,
  & > .ant-input-affix-wrapper:focus {
    border: none;
    box-shadow: none;
  }
}

// size
.XLargeInputWrapper {
  & > .ant-input,
  & > .ant-input-number,
  & > .ant-input-affix-wrapper,
  & > .ant-input-password {
    height: 46px;
    padding: 12px;
    .Text2();
  }
}

.LargeInputWrapper {
  & > .ant-input,
  & > .ant-input-number,
  & > .ant-input-affix-wrapper,
  & > .ant-input-password {
    padding: 10px 12px;
    height: 40px;
    .Text1();
  }
}

// textarea

.InputWrapper.TextAreaComponentWrapper {
  background-color: @primary-700;
  padding: 10px 12px;
  border-radius: 8px;
  border: 1px solid @primary-700;
  transition: background-color 0s;

  &:has(textarea:hover),
  &:has(textarea:active),
  &:has(textarea:focus) {
    border: 1px solid @separator-secondary;
  }

  &:has(textarea:focus) {
    background-color: @primary-800;
  }

}

.InputWrapper.TextAreaComponentWrapper textarea {
  resize: none;
  .VerticalScroll();
  transition: background-color 0s;

  &:focus {
    background-color: @primary-800;
  }
}

.ErrorText {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 2px;
  .Text1();
  color: @error-500;
}