.ProductCardContainer {
  display: flex;
  width: 247px;
  height: 412px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
  gap: 12px;
}

.ProductCardImageContainer {
  height: 247px;
  width: 247px;
  align-self: stretch;
  border-radius: 12px;
}

.ProductCardImage {
  height: 247px;
  width: 247px;
}

.ProductCardDetails {
  display: flex;
  height: 137px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.ProductCardPrice {
  .Headline3();
}

.ProductCardName {
  .Body2();
}

.ProductCardQuantityAvailable {
  .Caption();
  color: @neutral-600;
}

.ProductPreviewContainer {
  margin-top: 32px;
  display: flex;
  width: 382px;
  align-items: flex-start;
  gap: 16px;
}

.ProductPreviewImageContainer {
  width: 52px;
  height: 52px;
  border-radius: 8px;
}

.ProductPreviewImage {
  width: 52px;
  height: 52px;
  border-radius: 8px;
}

.ProductPreviewDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.ProductPreviewName {
  .Subtitle1();
  color: @neutral-600;
}

.ProductPreviewTotals {
  .Subtitle1();
}