.DashboardTableCardContainer {
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 8px 16px;
  border-radius: 16px;
  background-color: @primary-800;
}

.DashboardTableCardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  flex: 1 0 100%;
  .Subtitle1();
}

.DashboardTableCardItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc(50% - 8px);
}

.DashboardTableCardItemHeader {
  .Caption();
  color: @neutral-600;
}

.DashboardTableCardItemValue {
  .Text2();
}