.GamesReportStart {
  max-width: 864px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;

  &__Title {
    text-align: center;
    max-width: 520px;
    .Headline2
  }

  &__Subtitle {
    color: @neutral-600;
    text-align: center;
  }
}

.GamesReportFilter {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .VerticalScrollInvisible();
  max-height: calc(100vh - 175px);

  .-CheckboxWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .-TitleWrapper {
    display: flex;
    justify-content: space-between;
  }

  .-TimeWrapper {
    display: flex;
    margin-top: 8px;
    color: @neutral-500;
    .Caption();


    .-additionalTime {
      color: @neutral-600;
    }
  }
}

.GamesReportTableWrapper {
  .ant-table-wrapper {
    background-color: @primary-700;
    border-radius: 8px;
  }
}

.GamesReportTable {
  .ant-table-thead {
    height: 56px;

    .ant-table-cell {
      background-color: @primary-700;
      border-bottom: transparent;
      font-family: "Gilroy-500", sans-serif;
      font-weight: 500;

      p {

        margin: 0;
        line-height: 16px;

        span {
          font-weight: 700;
        }
      }
    }

    .ant-table-cell::before {
      background-color: transparent !important;
    }
  }

  .ant-table-tbody {
    .ant-table-row:has(.ant-table-row-expand-icon-cell):has(span):has(div):has(.GamesReportTableIcon):has(.-rotate) > td {
      border-bottom: 0 !important;
    }

    .ant-table-cell.ant-table-row-expand-icon-cell {
      padding-right: 0 !important;
    }

    .ant-table-cell {
      border-bottom: 1px solid @primary-700;
      height: 56px;

      &-row-hover {
        background: @primary-700 !important;
      }
    }
  }

  .ant-table-content {
    .TableHorizontalScroll();
  }

  .ant-pagination {
    .ant-pagination-item-active {
      background-color: transparent;

      a {
        color: @neutral-500;
      }
    }

    .ant-pagination-options {
      margin-left: auto;
      margin-right: 24px;
    }

    .ant-select-selector {
      background-color: transparent;
      border-color: @neutral-600;
      border-radius: 4px;
    }

    .ant-select-arrow {
      color: @neutral-500;
    }
  }

  .ant-pagination-options {
    margin-left: auto;

    svg path {
      fill: @neutral-500;
    }

    @media @mobile {
      display: inline-block;
    }
  }

  .ant-table-container {
    background-color: @primary-800;
  }

  .totalRow {
    font-family: "Lato-700", sans-serif;
  }

  .ant-table-cell {
    font-size: 14px;
  }

  .ant-table-cell {
    .-minus {
      color: @error-500;
    }
  }

  .ant-table-wrapper {
    background-color: @primary-800;
  }
}

.GamesReportTableIcon {
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &.-rotate {
    transform: rotate(180deg);
  }
}

.GamesReportTableInside {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__Row {
    display: flex;
    flex-direction: row;
    padding: 8px 0 8px 33px;
    font-size: 14px;
    font-family: 'Lato-400', sans-serif;
    line-height: 19px;

    &_Total {
      background-color: @primary-700;
      border-radius: 4px;
    }
  }

  &__Item {
    min-width: 135px;
    display: flex;
    align-items: center;

    &_Heading {
      display: flex;
      flex-direction: row;
      gap: 5px;
    }
  }
}

// 142 146 146 147 147 143

.GamesReportTableInside__Item:first-child {
  width: 15%;
  gap: 5px;
}

.GamesReportTableInside__Item:nth-child(2) {
  width: 15%
}

.GamesReportTableInside__Item:nth-child(3) {
  width: 15%;
  padding-left: 8px;
}

.GamesReportTableInside__Item:nth-child(4) {
  width: 15%;
  padding-left: 12px;
}

.GamesReportTableInside__Item:nth-child(5) {
  width: 15%;
  padding-left: 20px;
}

.GamesReportTableInside__Item:nth-child(6) {
  width: 15%;
  padding-left: 24px;
}

.GamesReportTableInside__Item:nth-child(7) {
  max-width: 124px;
  padding-left: 28px;
}

.GamesReportGamesList {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__Wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

@media @mobile {
  .GamesReportStart {
    padding: 32px 16px 41px;

    &__Title {
      text-align: center;
      max-width: 340px;
      .Headline3
    }

    &__Image {
      max-width: 280px;
    }
  }
}