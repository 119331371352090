.SliderComponentWrapper .ant-slider .ant-slider-rail,
.SliderComponentWrapper .ant-slider .ant-slider-track {
  height: 6px !important;
}

.ant-slider {
  margin: 0 !important;
}

.ant-slider-handle {
  background: @secondary-500 !important;
  box-sizing: border-box !important;
}

.SliderComponentWrapper .ant-slider-disabled .ant-slider-handle,
.SliderComponentWrapper .ant-slider-disabled .ant-slider-dot {
  border-color: @neutral-500 !important;
}

.ant-slider .ant-slider-handle::after {
  width: 14px !important;
  height: 14px !important;
  background-color: @secondary-500;
  box-shadow: 0 0 0 2px @secondary-500 !important;
  inset-inline-start: -1px;
  inset-block-start: -1px;
}

.ant-slider .ant-slider-handle:hover::after {
  box-shadow: 0 0 0 2px @secondary-500 !important;
}


.ant-slider .ant-slider-rail,
.ant-slider:hover .ant-slider-rail {
  background-color: @secondary-500;
}

.SliderComponentValueWrapper {
  padding-bottom: 4px;
  .Subtitle2();
  color: #2B2D33;
}
