.ButtonWrapper > .ant-btn-primary {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: @neutral-900;
  background-color: @secondary-500;
  box-shadow: none;

  &:not([disabled]) {
    &:hover,
    &:focus,
    &:active {
      color: @neutral-900;
      background-color: @secondary-500-hover;
    }

    &:focus-visible {
      outline: none;
      outline-offset: unset;
    }
  }

  &[disabled] {
    &,
    &:hover,
    &:focus,
    &:active {
      border: none;
      color: @neutral-900;
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}

.ButtonWrapper > .ant-btn-primary.ant-btn-loading {
  color: @neutral-900;
  background-color: @secondary-500;
}

.ButtonWrapper.AlertButtonWrapper > .ant-btn-primary {
  color: @neutral-500;
  background-color: @error-500;

  &:not([disabled]) {
    &:hover,
    &:focus,
    &:active {
      color: @neutral-500;
      background-color: @error-500;
    }
  }

  &[disabled] {
    &,
    &:hover,
    &:focus,
    &:active {
      color: @neutral-500;
    }
  }

  &.ant-btn-loading {
    color: @neutral-500;
    background-color: @error-500;
  }
}

.ButtonDefaultWrapper > .ant-btn-default {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 32px 12px 24px;
  border: 1px solid @secondary-500;
  color: @neutral-500;

  &:not([disabled]) {
    &:hover,
    &:focus,
    &:active {
      border: 1px solid @secondary-500;
      color: @secondary-500;
    }

    &:focus-visible {
      outline: none;
      outline-offset: unset;
    }
  }

  &[disabled] {
    &,
    &:hover,
    &:focus,
    &:active {
      color: @neutral-500;
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}

.ButtonDefaultWrapper.SecondaryButtonDefaultWrapper > .ant-btn-default {
  border: 1px solid @neutral-500;

  &:not([disabled]) {
    &:hover,
    &:focus,
    &:active {
      border: 1px solid @neutral-500;
      color: @neutral-500;
    }
  }
}

.ButtonDefaultWrapper.AlertButtonDefaultWrapper > .ant-btn-default {
  color: @error-500;
  border: 1px solid @error-500;

  &:not([disabled]) {
    &:hover,
    &:focus,
    &:active {
      color: @error-500;
      border: 1px solid @error-500;
    }
  }

  &[disabled] {
    &,
    &:hover,
    &:focus,
    &:active {
      color: @error-500;
      border: 1px solid @error-500;
    }
  }

  &.ant-btn-loading {
    color: @error-500;
    border: 1px solid @error-500;
  }
}

.XXLargeButtonWrapper > .ant-btn-primary,
.XXLargeButtonWrapper > .ant-btn-default {
  padding: 16px 24px;
  height: 52px;
  .Button1();
}

.XXLargeButtonWrapper.IconOnlyButtonWrapper > .ant-btn-primary,
.XXLargeButtonWrapper.IconOnlyButtonWrapper > .ant-btn-default {
  padding: 14px;
  width: 52px;
}

.XLargeButtonWrapper > .ant-btn-primary,
.XLargeButtonWrapper > .ant-btn-default {
  padding: 14px 24px;
  height: 48px;
  .Button1();
}

.XLargeButtonWrapper.IconOnlyButtonWrapper > .ant-btn-primary,
.XLargeButtonWrapper.IconOnlyButtonWrapper > .ant-btn-default {
  padding: 12px;
  width: 48px;
}

.LargeButtonWrapper > .ant-btn-primary,
.LargeButtonWrapper > .ant-btn-default {
  padding: 11px 20px;
  height: 40px;
  .Button1();
}

.LargeButtonWrapper.IconOnlyButtonWrapper > .ant-btn-primary,
.LargeButtonWrapper.IconOnlyButtonWrapper > .ant-btn-default {
  padding: 10px;
  width: 40px;
}

.MediumButtonWrapper > .ant-btn-primary,
.MediumButtonWrapper > .ant-btn-default {
  padding: 9px 20px;
  height: 36px;
  .Button2();
}

.MediumButtonWrapper.IconOnlyButtonWrapper > .ant-btn-primary,
.MediumButtonWrapper.IconOnlyButtonWrapper > .ant-btn-default {
  padding: 8px;
  width: 36px;
}

.SmallButtonWrapper > .ant-btn-primary,
.SmallButtonWrapper > .ant-btn-default {
  padding: 7px 16px;
  height: 32px;
  .Button2();
}

.SmallButtonWrapper.IconOnlyButtonWrapper > .ant-btn-primary,
.SmallButtonWrapper.IconOnlyButtonWrapper > .ant-btn-default {
  padding: 6px;
  width: 32px;
}

.GroupedButtonWrapper > .ant-btn-primary {
  width: 43px;
  height: 35px;
  color: @neutral-500;
  background-color: @primary-600;
  box-shadow: none;

  &:not([disabled]) {
    &:hover,
    &:focus,
    &:active {
      color: @neutral-500;
      background-color: @primary-600;
      box-shadow: none;
    }
  }

  &[disabled] {
    &,
    &:hover,
    &:focus,
    &:active {
      color: @primary-500;
      background-color: @primary-600;
      border-color: @primary-600;
      cursor: not-allowed;
      box-shadow: none;
    }
  }
}

.GroupedButtonWrapper.GroupedButtonWrapperError > .ant-btn-primary {
  background-color: @error-500;

  &:not([disabled]) {
    &:hover,
    &:focus,
    &:active {
      background-color: @error-500;
    }
  }

  &[disabled] {
    &,
    &:hover,
    &:focus,
    &:active {
      color: @neutral-600;
      background-color: @error-500;
      border-color: @error-500;
      cursor: not-allowed;
      box-shadow: none;
    }
  }
}

.LeftPositionGroupedButton > .ant-btn-primary {
  border-radius: 12px 0 0 12px;
}

.CenterPositionGroupedButton > .ant-btn-primary {
  border-radius: 0;
}

.RightPositionGroupedButton > .ant-btn-primary {
  border-radius: 0 12px 12px 0;
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// new buttons

// primary
.ButtonWrapperNew > .ant-btn-primary {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: @text-analogous;
  background-color: @button-primary;
  box-shadow: none;

  &:not([disabled]) {
    &:hover,
    &:focus,
    &:active {
      color: @text-analogous;
      background-color: @hover-button-primary;
    }

    &:focus-visible {
      outline: none;
      outline-offset: unset;
    }
  }

  &[disabled] {
    &,
    &:hover,
    &:focus,
    &:active {
      border: none;
      color: @text-analogous;
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  &.ant-btn-loading {
    color: @text-analogous;
    background-color: @button-primary;

    &:not([disabled]) {
      &:hover,
      &:focus,
      &:active {
        color: @text-analogous;
        background-color: @hover-button-primary;
      }
    }
  }

  // icon coloring
  & > .ant-btn-icon path {
    fill: currentColor;
  }

  & > .ant-btn-icon.ant-btn-loading-icon {
    & svg,
    & path {
      fill: currentColor;
    }
  }
}

// default
.ButtonDefaultWrapperNew > .ant-btn-default {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  &:focus-visible {
    outline: none;
    outline-offset: unset;
  }

  &[disabled] {
    &,
    &:hover,
    &:focus,
    &:active {
      color: @text-primary;
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  // icon coloring
  & > .ant-btn-icon path {
    fill: currentColor;
  }

  & > .ant-btn-icon.ant-btn-loading-icon {
    & svg,
    & path {
      fill: currentColor;
    }
  }
}

.ButtonDefaultWrapperNew.ButtonDefaultSecondaryWrapper > .ant-btn-default {
  border: 1px solid @button-primary;
  color: @text-primary;

  &:not([disabled]) {
    &:hover,
    &:focus,
    &:active {
      border: 1px solid @hover-button-primary;
      color: @hover-button-primary;

      // icon coloring
      & > .ant-btn-icon path {
        fill: currentColor;
      }

      & > .ant-btn-icon.ant-btn-loading-icon {
        & svg,
        & path {
          fill: currentColor;
        }
      }
    }
  }


}

.ButtonDefaultWrapperNew.ButtonDefaultTertiaryWrapper > .ant-btn-default {
  border: 1px solid @auxiliary-white;
  color: @text-primary;

  &:not([disabled]) {
    &:hover,
    &:focus,
    &:active {
      border: 1px solid @hover-button-primary;
      color: @hover-button-primary;

      // icon coloring
      & > .ant-btn-icon path {
        fill: @hover-button-primary;
      }

      & > .ant-btn-icon.ant-btn-loading-icon {
        & svg {
          fill: @hover-button-primary;
        }

        & path {
          fill: @hover-button-primary;
        }
      }
    }
  }
}

.ButtonDefaultWrapperNew.ButtonDefaultComplementaryWrapper > .ant-btn-default {
  border: 1px solid @red-420;
  color: @text-primary;

  &:not([disabled]) {
    &:hover,
    &:focus,
    &:active {
      border: 1px solid @red-420;
      color: @red-420;

      // icon coloring
      & > .ant-btn-icon path {
        fill: currentColor;
      }

      & > .ant-btn-icon.ant-btn-loading-icon {
        & svg,
        & path {
          fill: currentColor;
        }
      }
    }
  }
}

// sizes
.XLargeButtonWrapper > .ant-btn-primary,
.XLargeButtonWrapper > .ant-btn-default {
  padding: 12px 24px;
  height: 48px;
  .Button1();
}

.XLargeButtonWrapper.IconOnlyButtonWrapper > .ant-btn-primary,
.XLargeButtonWrapper.IconOnlyButtonWrapper > .ant-btn-default {
  padding: 12px;
  height: 48px;
  width: 48px;
}

.LargeButtonWrapper > .ant-btn-primary,
.LargeButtonWrapper > .ant-btn-default {
  padding: 11px 20px;
  height: 40px;
  .Button2();
}

.LargeButtonWrapper.IconOnlyButtonWrapper > .ant-btn-primary,
.LargeButtonWrapper.IconOnlyButtonWrapper > .ant-btn-default {
  padding: 10px;
  height: 40px;
  width: 40px;
}

.ant-btn-icon.ant-btn-icon:not(:last-child) {
  margin-inline-end: 0 !important;
}
