.SelectHour {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  .ant-select .ant-select-arrow {
    color: white !important;
  }
}

.SelectHourItem {
  .ant-select-selector {
    height: 44px !important;
    width: 130px !important;
    background-color: @primary-700 !important;
    border: 1px solid @primary-600 !important;
    @media @mobile {
      width: 150px !important;
    }

    .ant-select-selection-item {
      padding-top: 6px;
    }
  }
}

