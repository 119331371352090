.GridCenter {
  display: grid;
  place-items: center;
}

.SpinComponent .ant-spin span svg {
  path {
    fill: @secondary-500;
  }
}

