.SelectWrapper {
  .ant-select {
    width: 100%;

    .ant-select-arrow {
      transform: rotate(0deg);
      transition: transform 500ms ease;
    }
  }

  .ant-select-selector {
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: @primary-700 !important;
    border: none !important;
    box-shadow: none !important;
    padding: 12px 12px 12px 14px !important;

    &:not(.ant-select-disabled):hover,
    &:not(.ant-select-disabled):active,
    &:not(.ant-select-disabled):focus,
    &:not(.ant-select-disabled):focus-visible {
      border: none !important;
      box-shadow: none !important;


      .ant-select-selection-item {
        color: @neutral-500 !important;
        background-color: @primary-700;
      }
    }
  }

  .ant-select-selection-placeholder {
    color: @primary-500;
  }

  .ant-select-arrow {
    background-color: @primary-700 !important;

    & > svg > path {
      fill: @primary-500;
    }
  }

  .ant-select-open {
    &:not(.ant-select-disabled) .ant-select-selector {
      &,
      &:hover {
        border: none !important;
        box-shadow: none !important;
      }

      .ant-select-selection-item {
        color: @neutral-500 !important;
      }
    }

    .ant-select-arrow {
      transform: rotate(180deg);
      transition: transform 500ms ease;
    }
  }

  &.ant-select-disabled {
    opacity: 0.5;
  }
}

.SelectComponent.LargeSelectWrapper .ant-select-selector {
  height: 40px !important;
}

.SelectComponent.XLargeSelectWrapper .ant-select-selector {
  height: 46px !important;
}

.SelectGreyWrapperDisabled {
  opacity: 1;
}

.ErrorTreeSelectWrapper,
.ErrorSelectWrapper {
  .ErrorText {
    font-size: 10px;
    color: #e80012;
    margin-top: 2px;
  }

  .ant-select-selector {
    border-color: #e80012 !important;
  }
}

.ant-select-dropdown {
  border: none;
  box-shadow: none;
  border-radius: 4px !important;
  margin-top: -6px !important;
  background-color: @primary-700;

  .ant-select-item-option {
    padding: 8px 20px;
    font-weight: 500;
    background-color: @primary-700;

    &:not(.ant-select-item-option-disabled) {
      color: @neutral-500;
    }

    &.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      color: @neutral-500;
      background-color: @primary-100;
    }

    &-selected {
      color: @neutral-500 !important;
      background-color: @primary-600 !important;
    }
  }
}

.ant-select-selection-overflow-item > .ant-select-selection-item {
  background: #F4F4F4 !important;
  border-radius: 8px !important;
  padding: 0 8px !important;
}
