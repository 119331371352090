.HallPickerWrapper {
  width: 100vw;
  height: 100vh;
  background-color: @primary-900;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.HallPickerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 812px;
  background-color: @primary-800;
  border-radius: 24px;
  margin-top: 192px;
  // margin-top - 192, margin-bottom - 152. 192+152=344
  max-height: calc(100vh - 344px);
  padding-top: 32px;
  padding-bottom: 48px;

  @media @mobile {
    max-width: calc(100vw - 32px);
    margin: auto 16px;
    padding: 32px 0;
    max-height: calc(100vh - 135px);
  }
}

.HallPickerHeader {
  display: flex;
  flex-direction: row;
  align-items: center;

  @media @mobile {
    flex-direction: column;
    align-items: unset;
    align-self: flex-start;
    gap: 24px;
    width: 100%;
    padding: 0 16px;
  }
}

.HallPickerLogout {
  cursor: pointer;
  rotate: (90deg);
  width: 18px;
  height: 20px;
  margin-right: 8px;
}

.HallPickerTitle {
  .Headline2();
}

.HallPickerSearch .ant-input-affix-wrapper {
  width: 258px;
  margin-left: 35px;

  @media @mobile {
    margin-left: 0;
    width: 100%;
  }
}

.HallPickerSearch input::placeholder {
  color: @primary-200;
}

.HallPickerContentContainer {
  // margin for scroll
  margin-right: 4px;
  overflow-y: auto;
  .VerticalScroll();
}

.HallPickerContent {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 32px;
  margin: 0 128px;

  @media @mobile {
    margin: unset;
    justify-content: unset;
    gap: 16px;
    padding: 0 16px;
  }
}

.HallPickerItem {
  min-width: 156px;
  height: 56px;
  border: 1.5px solid @primary-900;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media @mobile {
    //min-width: 90px;
    min-width: calc(33% - 11px);
    height: 48px;
  }

  .HallPickerItemPrefix {
    margin-right: 4px;
    .Body2();
    color: @neutral-600;
  }

  .HallPickerItemLabel {
    .Button1();
    color: @neutral-500;
  }

  &:hover {
    border: none;
    background-color: @secondary-500;

    .HallPickerItemPrefix {
      color: @secondary-700;
    }

    .HallPickerItemLabel {
      color: @neutral-900;
    }
  }
}
