.LargeMenuButtonWrapper > .ant-btn-default {
  .Subtitle2();
  height: 48px;
}

.MediumMenuButtonWrapper > .ant-btn-default {
  .Text1();
  height: 40px;
}

.MinimizeMenuButtonWrapper > .ant-btn-default {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  border: 1px solid @primary-600;
  padding: 12px 32px 12px 24px;

  &:not([disabled]) {
    &:hover,
    &:focus,
    &:active {
      border: 1px solid @primary-500;
      color: @neutral-500;
    }
  }
}

.LogoutMenuButtonWrapper > .ant-btn-default {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 32px 12px 24px;
  border: none;
  background-color: @primary-800;
  color: @error-500;

  &:not([disabled]) {
    &:hover,
    &:focus,
    &:active {
      border: none;
      background-color: @primary-700;
      color: @error-500;
    }
  }
}

.MinimizeMenuButtonWrapper,
.LogoutMenuButtonWrapper {
  padding: 0 24px;
}

.MinimizeMenuButtonWrapper.LargeMenuButtonWrapper.MiniMenuButtonWrapper > .ant-btn-default,
.LogoutMenuButtonWrapper.LargeMenuButtonWrapper.MiniMenuButtonWrapper > .ant-btn-default {
  justify-content: center;
  gap: 0;
  width: 48px;
  height: 48px;
  padding: 12px;
}

.MinimizeMenuButtonWrapper.MediumMenuButtonWrapper.MiniMenuButtonWrapper > .ant-btn-default,
.LogoutMenuButtonWrapper.MediumMenuButtonWrapper.MiniMenuButtonWrapper > .ant-btn-default {
  justify-content: center;
  gap: 0;
  width: 40px;
  height: 40px;
  padding: 10px;
}

