@import "ChangePhoneTable/styles.less";
@import "ChangePhoneFormModal/styles.less";
@import "ChangePhoneStatusModal/styles.less";
@import "ChangePhoneWarningModal/styles.less";
@import "ChangePhoneFilter/styles.less";

.ChangePhoneHeader {
  display: flex;
  flex-direction: column;

  @media @mobile {
    position: relative;
    padding: 24px 16px 0;
  }
}

.ChangePhoneHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media @mobile {
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
  }
}

.ChangePhoneHeaderTextWrapper {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.ChangePhoneHeaderText {
  .Headline1();
  margin-right: 8px;

  @media @mobile {
    .Headline2();
  }
}

.ChangePhoneHeaderTooltip.TooltipComponentWrapper .ant-popover-content .ant-popover-inner {
  border-radius: 12px;
  background-color: @primary-800;
}

.ChangePhoneHeaderButton {
  margin-left: auto;

  @media @mobile {
    margin-left: unset;
  }
}

.ChangePhoneModal > .ant-modal-content {
  display: flex;
  flex-direction: column;
  background-color: @primary-800;

  & > .ant-modal-header {
    background-color: @primary-800;
  }
}

// padding
.ChangePhoneModal.ChangePhoneFormModal > .ant-modal-content {
  padding: 40px;

  @media @mobile {
    padding: 24px 16px;
  }
}

.ChangePhoneModal.ChangePhoneWarningModal > .ant-modal-content,
.ChangePhoneModal.ChangePhoneStatusModal > .ant-modal-content {
  padding: 32px;

  @media @mobile {
    padding: 24px 16px;
  }
}

// header
.ChangePhoneModal.ChangePhoneFormModal > .ant-modal-content > .ant-modal-header {
  margin-bottom: 24px;

  @media @mobile {
    margin-bottom: 16px;
  }
}

.ChangePhoneModal.ChangePhoneStatusModal > .ant-modal-content > .ant-modal-header {
  margin-bottom: 32px;

  @media @mobile {
    margin-bottom: 24px;
  }
}

.ChangePhoneModal.ChangePhoneWarningModal > .ant-modal-content > .ant-modal-header {
  margin-bottom: 16px;

  @media @mobile {
    margin-bottom: 8px;
  }
}

// footer
.ChangePhoneModal.ChangePhoneFormModal > .ant-modal-content > .ant-modal-footer {
  margin-top: 40px;
  text-align: start;

  @media @mobile {
    margin-top: 24px;
  }
}

.ChangePhoneModal.ChangePhoneStatusModal > .ant-modal-content > .ant-modal-footer {
  margin-top: 32px;
  text-align: center;

  @media @mobile {
    margin-top: 24px;
  }
}

.ChangePhoneModal.ChangePhoneWarningModal > .ant-modal-content > .ant-modal-footer {
  margin-top: 32px;

  @media @mobile {
    margin-top: 24px;
  }
}


.ChangePhoneModal.ChangePhoneModalMedium > .ant-modal-content > .ant-modal-footer {
  margin-top: 40px;
  text-align: start;

  @media @mobile {
    margin-top: 24px;
  }
}


.ChangePhoneModal .ant-modal-close {
  top: 24px;
  right: 24px;
}

.ChangePhoneMainModalHeader {
  .Headline1();

  @media @mobile {
    .Headline3();
  }
}

.ChangePhoneSecondaryModalHeader {
  .Headline2();

  @media @mobile {
    .Headline3();
  }
}

.changePhoneAddRequestForm label text {
  .Subtitle1();
}

.changePhoneAddRequestForm > .ant-form-item {
  margin-bottom: 24px;

  @media @mobile {
    margin-bottom: 16px;
  }
}

.changePhoneAddRequestForm > .ant-form-item:last-child {
  margin-bottom: 0;
}

.ChangePhoneModal .ant-form-item-required::before {
  display: none !important;
}

.ChangePhoneSpin {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 24px;
  max-height: 647px;
}

