.CheckboxWrapper {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.LargeCheckboxWrapper {
  gap: 12px;
  height: 24px;

  .CheckboxText {
    .Subtitle2();
  }
}

.MediumCheckboxWrapper {
  gap: 8px;
  height: 20px;

  .CheckboxText {
    .Text1();
  }
}


.CheckboxWrapper {
  .ant-checkbox-checked:not(.ant-checkbox-indeterminate) .ant-checkbox-inner {
    background: @secondary-500 url(/components/Checkbox/check.svg) no-repeat 70% 50%;

    &:after {
      display: none !important;
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: @secondary-500;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: @secondary-500;
    border-color: @secondary-500;
  }

  .ant-checkbox-wrapper-disabled,
  .ant-checkbox-wrapper-disabled + .CheckboxText {
    opacity: 0.4;
  }
}

.LargeCheckboxWrapper {
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    background: none;
    border: 2px solid @neutral-600;
    border-radius: 4px;

    &::after {
      display: none;
    }
  }
}

.MediumCheckboxWrapper {
  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    background: none;
    border: 2px solid @neutral-600;
    border-radius: 4px;

    &::after {
      display: none;
    }
  }
}
