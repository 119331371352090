.OrderCardWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  width: 307px;
  height: 176px;
  padding: 17px 15px;
  border-radius: 16px;
  background-color: @primary-800;
}

.OrderCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.OrderCardHeaderText {
  .Subtitle2();
}

.OrderCardInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  gap: 4px;
  width: 275px;
}

.OrderCardInfoTotal {
  .Headline2();
}

.OrderCardInfoStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  height: 24px;
  padding: 4px 8px;
  border-radius: 20px;
  background-color: @primary-700;
}

.OrderCardInfoStatusColor {
  width: 8px;
  height: 8px;
  border-radius: 8px;
}

.OrderCardInfoStatusText {
  .Overline();
  letter-spacing: 1.5px;
  text-transform: uppercase;
  text-align: center;
}

.OrderCardDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  height: 32px;
}

.OrderCardDetailsText {
  .Overline();
  color: @primary-100;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}