.RangePickerWrapper {
  .ant-picker {
    border-radius: 8px;
    background-color: @primary-700;
    border: none !important;
    padding: 12px !important;
    width: 100%;

    &:hover {
      border: none !important;
    }

    &-suffix {
      margin-left: auto;

      & > svg > path {
        fill: @primary-500;
      }
    }

    &-input {
      caret-color: transparent;
    }
  }
}

.RangePickerWrapper.LargePickerWrapper {
  .ant-picker {
    height: 40px;
  }
}

.RangePickerWrapper.XLargePickerWrapper {
  .ant-picker {
    height: 46px;
  }
}

.RangePickerWrapper .ant-picker-focused.ant-picker:hover,
.RangePickerWrapper .ant-picker-focused {
  border: none !important;
  box-shadow: none !important;
}

.RangePickerWrapper {
  .ant-picker .ant-picker-disabled:hover {
    border: none !important;
  }

  .ant-picker-range .ant-picker-active-bar {
    background: @primary-700;
  }
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: @neutral-500 !important;
}

.ant-picker-cell:not(.ant-picker-cell-in-view) .ant-picker-cell-inner {
  color: @primary-600;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: @primary-900;
}

.ant-picker .ant-picker-input-placeholder > input {
  color: @neutral-500 !important;
}

.ant-picker .ant-picker-input > input::placeholder {
  color: @primary-500 !important;
}

.ant-picker-dropdown .ant-picker-header > button {
  color: @neutral-600;
}

.ant-picker-dropdown .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  border-color: @primary-600 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  border: none !important;
}

.ant-picker-date-panel {
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    border: none !important;
    background: @secondary-600 !important;
    gap: 0;
    height: 34px !important;
    inset-inline-start: 0;
  }

  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-start-single.ant-picker-cell-range-hover-start.ant-picker-cell-selected:after {
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
  }

  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover-end {
    border-radius: 17px;

    &:before {
      width: 100%;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover {
    color: @secondary-900;
  }

  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-end {
    &:before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: 34px !important;
      width: 100%;
    }
  }

  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child:after {
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
  }

  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child:after {
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
  }

  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover-end {
    color: @secondary-900;

    &:before {
      background: @secondary-500 !important;
      height: 34px !important;
      border-radius: 17px;
    }

    &:after {
      border-top-right-radius: 17px;
      border-bottom-right-radius: 17px;
      color: @secondary-900 !important;
    }
  }
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: @primary-600 !important;
}

.ant-picker-header button {
  font-weight: bold !important;
}

.ant-picker-dropdown .ant-picker-panel-container {
  background-color: @primary-700;
  .Shadow();
}

.ant-picker-cell-today .ant-picker-cell-inner {
  background-color: @primary-600;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: none;
}

.ant-picker-today-btn {
  color: @neutral-500 !important;
}

.ant-picker-today-btn:active,
.ant-picker-today-btn:hover {
  color: @secondary-500-hover !important;
}

.ant-picker-header-view button:hover {
  color: #1066f2 !important;
}


.RangePickerWrapper .ant-picker-disabled {
  opacity: 0.5;
}

.ant-picker-dropdown {
  z-index: 9999 !important;
}

.ant-picker-dropdown {
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: @secondary-600 !important;
  }
}

.ant-picker-dropdown {
  .ant-picker-cell-in-view.ant-picker-cell-in-range:first-child::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):first-child::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):first-child::before {
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
  }
}

.ant-picker-dropdown {
  .ant-picker-cell-in-view.ant-picker-cell-in-range:not(.ant-picker-cell-selected):last-child::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-selected):last-child::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-selected):last-child::before {
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
  }
}

.ErrorPickerWrapper .ant-picker,
.ErrorRangePickerWrapper .ant-picker-range {
  border-color: @error-500 !important;
}

.DatePickerWrapperError > .ant-picker,
.TimePickerWrapperError > .ant-picker {
  border: 1px solid @error-500 !important;
}

.ant-picker-input > input {
  color: @neutral-500;
}

.ant-picker-input > input::placeholder {
  color: @neutral-500 !important;
}

.ant-picker-range {
  display: flex !important;
  flex-direction: row !important;
}

.ant-picker-range > .ant-picker-input {
  display: inline-block !important;
  max-width: 80px;
}

.ant-picker-range > .ant-picker-input input {
  text-align: center !important;
}

.DatePickerSeparatorWrapper {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: @primary-500;
}

@media screen and @mobile {
  .ant-picker-panels {
    flex-direction: column !important;
  }
}

.ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner {
  min-width: 34px;
  height: 34px;
  line-height: 34px;
  border-radius: 17px;
}

// range picker
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 17px;
  background-color: @secondary-500;
}

.ant-picker-dropdown .ant-picker-cell::before {
  height: 34px;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 17px;
  background-color: @secondary-500;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: @neutral-900 !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range .ant-picker-cell-inner {
  color: @neutral-900 !important;
}

.ant-picker-dropdown .ant-picker-content th {
  color: @primary-500;

  &:nth-child(n + 6) {
    color: @error-500;
  }
}

.ant-picker-clear:has(.RangePickerReset) {
  opacity: 1;
  background: none;
  top: -50%;
}

.RangePickerReset {
  .Subtitle1();
  cursor: pointer;
}

// time picker

.ant-picker .ant-picker-input .ant-picker-suffix span svg {
  fill: @primary-500;
}

.ant-picker.ant-picker-status-success .ant-picker-input .ant-picker-suffix span svg {
  fill: @icon-complementary;
}

.ant-picker-dropdown {

  .ant-picker-content {
    padding: 12px 6px !important;
  }

  .ant-picker-time-panel {
    padding-top: unset !important;
  }

  .ant-picker-time-panel-column {
    width: unset;
    margin: unset;
    .VerticalScroll();

    &:not(:first-child) {
      border-inline-start: none;
    }

    &::after {
      display: none !important;
    }

    .ant-picker-time-panel-cell {
      height: 34px !important;
      width: 34px !important;
      margin-inline: unset !important;
    }

    .ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected {
      border-radius: 40px;
      background-color: @icon-secondary;
      color: @text-analogous;

      & > .ant-picker-time-panel-cell-inner {
        border-radius: 40px;
        background-color: @icon-secondary;
        color: @text-analogous;
      }
    }

    .ant-picker-time-panel-cell-inner {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      height: 34px !important;
      width: 34px !important;
      padding-inline-start: unset !important;
      transition: unset !important;
    }

  }
}

.ant-picker-footer {
  border-top: none !important;
  line-height: unset !important;

  & .ant-picker-ranges {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 12px 12px;
    line-height: unset;

    & > .ant-picker-ok {
      margin-inline-start: unset;
    }

    & > .ant-picker-ok > button.ant-btn.ant-btn-primary.ant-btn-sm {
      border: 1px solid @button-primary;
      color: @text-primary;
      background-color: @primary-700;
      box-shadow: none;
      padding: 3px 12px;
      border-radius: 8px;
      text-align: center;
      font-family: 'Gilroy-500', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;

      &[disabled] {
        &,
        &:hover,
        &:focus,
        &:active {
          color: @text-primary;
          opacity: 0.4;
          cursor: not-allowed;
        }
      }

      &:not([disabled]) {
        &:hover,
        &:focus,
        &:active {
          border: 1px solid @hover-button-primary;
          color: @hover-button-primary;
        }
      }
    }
  }
}
