.Switcher {
  display: flex;
  max-width: 340px;
  background-color: @background-secondary;
  border-radius: 12px;
  color: @text-secondary;
  padding: 2px 2px;
  min-height: 38px;
  align-self: flex-end;

  &__Item {
    .Button1();
    border-radius: 12px;
    min-width: 169px;
    max-width: 169px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.4s ease-in-out;

    &-Active {
      color: @neutral-500;
      background-color: @hover-tab-primary;
      padding: 7px 10px;
      border-radius: 10px;
    }

    &-Disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.4;
    }
  }
}