@import "./components/styles";
@import "./WorkersFilter/styles";

.workersTable {
  .ant-pagination-options {
    margin-left: auto;

    svg path {
      fill: @neutral-500;
    }

    @media @mobile {
      display: inline-block;
    }
  }

  .ant-select-selector {
    background-color: transparent !important;
    border: 1px solid @neutral-600 !important;
  }

  .ant-table-tbody {
    background-color: @primary-800;

    .ant-table-cell {
      font-weight: 400;
      font-size: 14px;
    }
  }

  .ant-table-thead {
    .ant-table-cell {
      background-color: @primary-700;
      font-weight: 500;
      font-size: 14px;

      &:before {
        display: none;
      }
    }
  }

  .ant-table-content {
    .TableHorizontalScroll();
  }

  .ant-pagination {
    background-color: @primary-700;
    padding: 12px 12px 12px 16px;
    margin: 0 !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: 14px;
    font-weight: 500;

    .ant-pagination-item-active {
      background-color: transparent;

      a {
        color: white;
      }
    }
  }
}

.workersTableNotFound {
  transform: translate(0, 50%);
  @media @mobile {
    transform: none;
    margin-top: 88px;
  }
}

.workersMoreBtn {
  display: flex;
  cursor: pointer;
  height: 20px;
  align-items: center;

  svg {
    height: 20px;
  }
}

.workersActionMenu {
  display: flex;
  align-items: center;
  gap: 10px
}

.workersDropdown {
  .ant-dropdown-menu {
    background-color: @primary-700;

    .ant-dropdown-menu-item:hover {
      background-color: @primary-600;
    }
  }

  .HiddenItem {
    display: none !important;
  }
}

.workersAddBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 40px !important;
  width: 216px !important;
  font-size: 14px !important;

  .workersAddBtnText {
    font-size: 14px;
    line-height: 18px;
    color: @neutral-900;
    font-weight: 600;
  }
}

.workersNotFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  gap: 32px;
}

.workersMobileBtn {
  margin-left: 16px;
}

.workersSpin {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: @primary-800;
  border-radius: 24px;
  max-height: 647px;
}

.WorkersHeaderButton {
  margin-left: auto;

  @media @mobile {
    margin-left: unset;
  }
}