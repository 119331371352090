.SmsMailingCreateStep {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.SmsMailingCreateStep:has(.SmsMailingCreateStepContent.SmsMailingDisplayNone) .SmsMailingCreateStepHeader {
  margin-bottom: 0;
}

.SmsMailingCreateStepHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
}

.SmsMailingCreateStepHeader > .TooltipComponentTargetWrapper {
  margin-top: 0;
}

.SmsMailingCreateStepHeaderNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 4px;
  border-radius: 80px;
  background-color: @background-primary;
  .Button();
}

.SmsMailingCreateStepHeaderNumber.SmsMailingCreateStepHeaderNumberActive {
  background-color: @icon-primary;
  color: @text-tertiary;
}

.SmsMailingCreateStepHeaderText {
  .Subtitle1();
}

.SmsMailingCreateStepHr {
  background-color: @separator-tertiary;
  margin: 32px 0;
  height: 1px;
  border: 0;
}

.SmsMailingCreateStepContent .ant-form-item-control-input {
  min-height: 0;
}