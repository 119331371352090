.workersFilterContainer {
  display: flex;
  flex-direction: column;
  width: 272px;
  align-items: flex-start;
  margin-top: 32px;
  gap: 28px;

  .switchWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .ant-switch {
      background: @neutral-600;

      .ant-switch-handle::before {
        background-color: white;
      }

      &-checked {
        background-color: @secondary-500;

        .ant-switch-handle::before {
          background-color: white !important;
        }
      }
    }

    .ant-switch-handle::before {
      background-color: @primary-700;
    }
  }


}

.filterWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 14px;

  .ant-input-affix-wrapper {
    height: 48px !important;
  }

  input::placeholder {
    font-size: 14px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media @mobile {
    input::placeholder {
      font-size: 16px;
    }
  }
}

.subtitleContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.workersFilterSubtitle {
  color: @primary-200;
  font-size: 14px;

  &.-dark {
    color: white;
    cursor: pointer;
  }
}

.workersFilterSubtitleDark {
  color: @primary-600;
}

@media @mobile {
  width: 100%;
  align-items: stretch;
}

@media @mobile {
  .workersFilterContainer {
    width: 100%;
  }
}

