.DashboardChartsWrapper {
  background-color: @primary-800;
}

.highcharts-credits {
  display: none !important;
}

.DashboardChartTooltip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.DashboardChartTooltipMainText {
  .Body2();
  color: @secondary-500;
}

.DashboardChartTooltipSubText {
  .Caption();
  color: @neutral-600;
}